


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  max-width: 600px;
  margin: auto;
}

figure{
  margin: 0;
  padding: 0;
}

.App{
  padding: 2rem 0 0;
}

.logo_temp{
  width: 120px;
  margin: auto;
  img{
    width: 100%;
  }
}

.index_top{
  background: url(img/top_img.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  .logo{
    margin: auto;
    margin-bottom: 3rem;
    max-width: 240px;
    img{
      width: 100%;
    }
  }
  .h2_midashi{
    color: #5FC1C5;
    text-shadow: 2px 2px 2px #5c5a5a30;
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
    font-weight: bold;
    span{
      color: #F29C9F;
    }
  }
  .top_img_02{
      display: flex;
      justify-content: center;
      width: 95%;
      margin: auto;
      align-items: flex-end;
      figure{
        width: 35%;
        padding: 0;
        margin: 0;
        img{
          width: 100%;
        }
      }
      p{
          padding: 0;
          margin: 0;
          width: 65%;
          text-align: left;
      }
  }
}

.sec__affi{
  margin-top:2rem ;
  padding: 1rem;
  background: #e5e5e5;
  figure{
    text-align: center;
    *{
      text-align: center;
      margin: auto;
    }
  }
}

.sec_room{
  background-image: url(img/room_bg.svg);
  margin-top: 2rem;
  width: 100%;
  padding-top:1rem;
  figure{
    width: 95%;
    margin: auto;
    img{
      width: 100%;
    }
  }
}

footer{
  background: #EFEFEF;
  margin-top: 2rem;
  padding: 2rem 0;
  div{
    a{
      display: block;
    }
  }
}

.room__setting{
    margin-top: 2rem;
    background-image: url(img/temp_bg.svg);
    background-position: center;
    padding: 1rem;
    *{
      box-sizing: border-box;
    }
    >div{
      background: #FBFBFB;
      padding: 0.5rem;
      h2,p{
        // padding:0 ;
        // margin: 0;
      }
      h2{
        font-weight: bold;
        font-size: 1.4rem;
      }
    }
    .return{
      margin: 1.5rem auto;
      background: #FAEAF9;
      border-radius: 5px;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      width: 90%;
      ul{
        margin-top: 1rem;
        li{
          color: #EE0000;
          text-align: left;


          span{
            display: block;
          }
        }
        }
    }
}

.qr_code{
  svg{
    margin: auto;
    // width: 300px;
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}